
.timeline-controls {
  position: relative;
  display: inline-block;    
  height: 42px;
  top: 11px;
  left: 50%;
  margin-left: -184px;
  color: #bbbbbb;

    label {
      margin-right: 8px;
    }

    input{
      background: none;
      border: none;
      text-align: center;
      color: #bbbbbb;
      outline: none;
    }

    .playBtn,
    .pauseBtn,
    .volumeOn,
    .volumeOff {
      cursor: pointer;
      position: relative;
      float: left;
      width: 42px;
      height: 42px;
      border-radius: 50%;
      text-align: center;

      svg {
        font-size: 16px;
        height: 40px;
      }
    }

    .playBtn,
    .pauseBtn {
      svg {
        fill: #47C3F2;
      }
    }

    .pauseBtn{
      svg {
        fill: #87ffe6;
      }
    }

    .forwardBtn,
    .rewindBtn {
      cursor: pointer;
      float: left;
      position: relative;
      width: 42px;
      height: 42px;
      border-radius: 50%;
      text-align: center;

      svg {
        fill: #bbbbbb;
        font-size: 16px;
        height: 40px;
      }

      &:hover {

        svg {
          fill: #ffffff;
        }
      }
    }

    .rewindBtn {
      left: 21px;
    }
    .forwardBtn {
      right: 21px;
    }
    
    .volumeOn,
    .volumeOff {
      
      svg {
        fill: #ffffff;
      }
    }
    .volumeOn {
      &:hover {
        svg {
          fill: #ff9090;
        }
      }
    }
    .volumeOff {
      height: 0;
    }

    &.muted {
      .volumeOn {
        height: 0;
      }
      .volumeOff {
        height: auto;

        svg {
          fill: #ff5f5f;
        }
      }
    }

    .time-display {
      float: left;
      width: 200px;
      background-color: #2C2C2E;
      height: 42px;

      .time-left,
      .time-right {
        float: left;
        width: 96px;
        background-color: #3A3A3C;
        height: inherit;
        padding: 4px 8px 0px 8px;
        color: #ffffff;

        span {
          display: block;
          line-height: 1;

          &.frame {
            font-size: 21px;
          }

          &.time {
            font-size: 9px;
            line-height: 15px;
          }
        }
      }

      .time-left {
        text-align: right;
      }

      .time-right {
        text-align: left;
        margin-left: 8px;
      }

    }

}


@media only screen and (min-width: 1018px) {
  .timeline-controls {
    position: relative;
    display: inline-block;    
    height: 42px;
    top: 11px;
    left: 50%;
    margin-left: -184px;
    color: #bbbbbb;
  
      label {
        margin-right: 8px;
      }
  
      input{
        background: none;
        border: none;
        text-align: center;
        color: #bbbbbb;
        outline: none;
      }
  
      .playBtn,
      .pauseBtn,
      .volumeOn,
      .volumeOff {
        cursor: pointer;
        position: relative;
        float: left;
        width: 42px;
        height: 42px;
        border-radius: 50%;
        text-align: center;
  
        svg {
          font-size: 16px;
          height: 40px;
        }
      }
  
      .playBtn,
      .pauseBtn {
        svg {
          fill: #47C3F2;
        }
      }
  
      .pauseBtn{
        svg {
          fill: #87ffe6;
        }
      }
  
      .forwardBtn,
      .rewindBtn {
        cursor: pointer;
        float: left;
        position: relative;
        width: 42px;
        height: 42px;
        border-radius: 50%;
        text-align: center;
  
        svg {
          fill: #bbbbbb;
          font-size: 16px;
          height: 40px;
        }
  
        &:hover {
  
          svg {
            fill: #ffffff;
          }
        }
      }
  
      .rewindBtn {
        left: 21px;
      }
      .forwardBtn {
        right: 21px;
      }
      
      .volumeOn,
      .volumeOff {
        
        svg {
          fill: #ffffff;
        }
      }
      .volumeOn {
        &:hover {
          svg {
            fill: #ff9090;
          }
        }
      }
      .volumeOff {
        height: 0;
      }
  
      &.muted {
        .volumeOn {
          height: 0;
        }
        .volumeOff {
          height: auto;
  
          svg {
            fill: #ff5f5f;
          }
        }
      }
  
      .time-display {
        float: left;
        width: 200px;
        background-color: #2C2C2E;
        height: 42px;
  
        .time-left,
        .time-right {
          float: left;
          width: 96px;
          background-color: #3A3A3C;
          height: inherit;
          padding: 4px 8px 0px 8px;
          color: #ffffff;
  
          span {
            display: block;
            line-height: 1;
  
            &.frame {
              font-size: 21px;
            }
  
            &.time {
              font-size: 9px;
              line-height: 15px;
            }
          }
        }
  
        .time-left {
          text-align: right;
        }
  
        .time-right {
          text-align: left;
          margin-left: 8px;
        }
  
      }
  
  }
}