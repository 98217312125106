.overlay-container{
  position: relative;
  display: block;
  padding-top: 4px;
  padding-bottom: 4px;
}
.overlay-wrapper{
  overflow: hidden;

  .overlay {
    display: block;
    position: absolute;
    background-color:#8ed7ff;
    cursor: grab;
    border-radius: 8px;

    .toggleOverlay-container {
      text-align: center;

      .overlay_number {
        pointer-events: none;
      }
    }
    
    &.inactive {
      background-color: #909090;
    }

  }
}

    