/* -------  VARIABLES  --------- */
$heightButtons: 40px;
$navHeight: 57px;
/* ----------------------------- */

.toolBar {
    width: 300px;
    padding: 10px 0;
    position: absolute;
    right: 24px;
    height: 42px;
    top: 65px;
}

.buttons {
    width: 40px;
    padding:8px;
    margin: auto;
    height: $heightButtons;
    vertical-align: middle;
    border-radius: 50%;
    text-align: center;
    background-color: #3a3a3c;
    border-bottom: 1px solid #000000;
    color: #ffffff;

    &:hover {
        color: #47C3F2;
    }

  
    &.disabled {
        cursor: not-allowed;
        color: #8E8E93!important;

        &:hover {
            color: #8E8E93!important;;
        }
    }
}

.scene-modal-row {
    display: block;
    margin-bottom: 8px;

    label {
        display: inline-block;
        width: 120px;
        text-align: right;
        padding-right: 16px;
    }
    
    input {
        padding: 0 8px;
        background-color: transparent;
        padding-bottom: 4px;
        border: none;
        outline: none;
        color: #aaa;
        border-bottom: 1px solid transparent;

        &:focus,
        &:active {
            border-bottom-color: #47C3F2;
        }
    }
}

.save-template-prompt {
    input {
        background-color: transparent;
        color: #aaaaaa;
        border: none;
        border-bottom: 1px solid transparent;
        outline: none;
        width: 100%;
        border-bottom-color: #666666;
        padding-bottom: 6px;

        &:active,
        &:focus {
            border-bottom-color: #47C3F2;
        }
    }

    .input-poster-wrapper {
           
        .error-container {
            font-size: 13px;
            line-height: 16px;
            color: #ff5d5d;
        }
    }
}

@media only screen and (min-width: 1018px) {

    .toolBar {
        width: 300px;
        padding: 10px 0;
        position: absolute;
        right: 16px;
        top: 2px;
    }
    

}
