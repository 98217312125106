
.overlaySettingsContainer {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: inherit;
    background-color: #2C2C2E;
    border-left: 1px solid #000000;

    .closeBtn {
        float: right;
        cursor: pointer;
    }

    label {
        display: inline-block;
        width: 80px;
        margin-bottom: 16px;
        color: #8E8E93;
        font-size: 11px;
        line-height: 20px;
        transform: none;
    }

    input {
        background-color: transparent;
        color: #8E8E93;
        line-height: 20px;
        padding-left: 8px;
        border: none;
        border-bottom: 1px dashed #8E8E93;
        outline: none;

        &:focus,
        &:active {
            border-bottom-color: #3395ff;
        }
    }

    .pointBlock {
        overflow: auto;

        label {
            float: left;
            width: 20px;
            margin-left: 24px;

        }

        input.trackerPoint{
            float: left;
            width: 64px;
        }
    }

    .selectControl {
        height: 32px;
        width: 100%;

        .MuiInputLabel-shrink {
            transform: translate(0, 1.5px);
            transform-origin: top left;
        }

        .selectInput {
            position: absolute;
            top: -8px;
            margin-top: 0;
            left: 80px;

            .MuiInputBase-inputSelect {
                font-size: 12px;
                color: #8E8E93;
            }
        }

    }

    .applyBtn,
    .deleteBtn {
        margin-top: 24px;
        box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.4);
    }

    .applyBtn {
        float: right;
    }

    .deleteBtn {
        float: left;
    }

    .loadScene-container {
        padding-left: 16px;
        border-left: 2px solid #666666;

        .newSceneCheckbox {
            width: 164px !important;
            display: block!important;
            float: right;
            margin: 0;
            
            .MuiFormControlLabel-label {
                position: relative;
                display: inline-block;
                top: 2px;
                line-height: 24px;
            }
        }

        .TargetCheckbox {
            width: 164px !important;
            display: block!important;
            margin: 0;
            
            .MuiFormControlLabel-label {
                position: relative;
                display: inline-block;
                top: 2px;
                line-height: 24px;
            }
        }

        .scenes-list {
            display: inline;

            .selectInput {
                left: 0;
            }
        }

        button {
            float: right;
            margin-right: 32px;
            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.4);
        }
    }
    

    .ov-row-wrapper {
        padding-bottom: 8px;

        span.error-container {
            display: block;
            margin-left: 80px;
            font-size: 13px;
            line-height: 16px;
            color: #ff5d5d;
            position: relative;
            top: -10px;
        }
    }
}


.tooltip-ov-type {
    width: 280px;
    font-size: 13px;
}