.variablesContainer {
    position: absolute;
    background-color: #111111;
    top: 0;
    padding: 0 32px;
    z-index: 1000;
    right: 0;
    width: 100%;
    height: 100%;

    .variables-inner-container {
        position: relative;
        padding: 64px 0 40px 0;

        .var-back-btn {
            cursor: pointer;

            &:hover {
                fill: #3395ff;
            }
        }

        .varPanelTitle {
            text-align: center;
            font-size: 20px;
            margin-top: 24px;
            // padding-left: 40px;
        }

        .addBtn {
            width: 34px;
            height: 34px;
            padding: 0 6px;
            color: #aaaaaa;
            border-radius: 50%;
            border: 1px solid #aaaaaa;
            margin-left: 80px;
            cursor: pointer;
            transition: all ease 0.2s;
            position: relative;
            top: -4px;
    
            &:hover {
                color: #888888;
                background-color: #eeeeee;
                border-color: #eeeeee;
            }
        }

        .applyVarBtn {
            color:#999999;

            &:hover {
                color:#eeeeee;
                background-color: #3395ff;
            }
        }

        .delVarBtn {
            color: #999999;;
            float: right;

            &:hover {
                color:#eeeeee;
                background-color: #ff4500;
            }
        }

        .varInnerContainer {
            clear: both;

            span {
                min-width: 150px;
                display: inline-block;
                text-align: right;
            }

            input {
                max-width: 100px;
                margin-right: 24px;
                margin-top: 16px;
                margin-left: 24px;
                min-width: 150px;
            }

            .checkBtn,
            .delBtn {
                width: 32px;
                height: 32px;
                padding: 0 6px;
                color: #606060;
                border-radius: 50%;
                border: 1px solid #606060;
                cursor: pointer;
                transition: all ease 0.2s;
            }
        
            .delBtn{
                margin-left: 16px;
        
                &:hover {
                    color: #eeeeee;
                    background-color: #ff4500;
                    border-color: #ff4500;
                }
            }
        
            .checkBtn {
                &:hover {
                    color: #eeeeee;
                    background-color: #2b945a;
                    border-color: #2b945a;
                }
            }
        }
    }
}