.closeEditor-btn {
    width: 40px;
    text-align: center;
    padding: 8px 0;
    cursor: pointer;
    position: absolute;
    top: 0;
    left: -40px;
    display: block;
    z-index: 100;
    background-color: #212121;
    border-top-left-radius: 14px;
    border-bottom-left-radius: 14px;

    &:hover {
        background-color: #444444;
    }
}