.elements-bar {
    // background-color: #2f2f2f;
    padding-left: 24px;
    overflow: auto;

    button {
        display: block;
        float: left;
        margin-right: 24px;
        padding: 0;
        line-height: 39px;
        text-align: center;
        color: #888888;
        background-color: transparent;
        border: none;
        border-bottom: 1px solid transparent;
        outline: none;
        cursor: pointer;
    }

    &.el1 {
        button:nth-child(1) {
            border-bottom-color: #00a8ff; 
        }
    }

    &.el2 {
        button:nth-child(2) {
            border-bottom-color: #00a8ff; 
        }
    }

    &.el3 {
        button:nth-child(3) {
            border-bottom-color: #00a8ff; 
        }
    }
}