
/* -----------------------------------------------------------------------------------  
                    G E N E R I C   E L E M E N T S   S T Y L E S
   ----------------------------------------------------------------------------------- */
* {
    font-family: "Open Sans", sans-serif;
}

body {
  overflow: hidden;
}

#root {
    width: 100vw;
    height: 100vh;
}

video {
    z-index: -1;
 }


/* -----------------------------------------------------------------------------------
                    C O M M O N   C L A S S E S   &   S T Y L E S
   ----------------------------------------------------------------------------------- */
.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}

svg {
    vertical-align: middle;
}

.btn-with-border {
    display: inline-block!important;
    background-color: #3a3a3c;
    border: 1px solid #020202;
    cursor: pointer;
}

.pageContainer {
    clear: both;
    height: calc(100% - 57px);
    overflow: auto;
    padding: 16px;

    h2 {
        margin: 0 0 40px 40px;
    }
}

button {

    &:active,
    &:visited,
    &:focus {
        outline: none!important;
    }
}

// .adminComponent {
//   display: none;
// }



/* -----------------------------------------------------------------------------------  
                        L  I  N  E  A  R    S  P  I  N  N  E  R
   ----------------------------------------------------------------------------------- */
   .lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ellipsis div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #fff;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
  }
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }
  /* -----------------------------------------------------------------------------------  */
  /* -----------------------------------------------------------------------------------  */


