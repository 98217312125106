.editor-legend-btn {
    width: 40px;
    text-align: center;
    line-height: 40px;
    font-size: 10px;
    padding: 8px 0;
    cursor: pointer;
    position: absolute;
    bottom: 60px;
    left: -40px;
    display: block;
    z-index: 100;
    background-color: #212121;
    border-top-left-radius: 14px;
    border-bottom-left-radius: 14px;

    &:hover {
        background-color: #065884;
    }

    svg {
        width: 32px;
        height: 32px;
        position: relative;
        left: 2px;
    }
}