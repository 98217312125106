
.overlayProps-container {
    height: 40px;

    .overlayProps {
        height: inherit;

        .overlay-name {
        float: left;
        display: block;
        width: 100px;
        line-height: 39px;
        padding-right: 8px;
        border-right: 1px solid #000000;

            .activeOverlayIndicator {
                display: block;
                float: left;
                margin: 11px;
                width: 16px;
                height: 16px;
                border-radius: 50%;
                background-color: #8E8E93;
            }
        }

        .toggleOverlay,
        .overlaySettings {
            float: left;
            margin-top: 4px;
            margin-left: 16px;
            padding: 0 6px;
            border: 1px solid #8E8E93;
            border-radius: 6px;
            color:#8E8E93;
            cursor: pointer;

            svg {
                height: 28px;
                width: 16px;
            }

            &:hover {
                color: #cccccc;
                border-color: #cccccc;
                }
        }

        &.active {
            .activeOverlayIndicator {
                background-color: #47C3F2;
            }
        }

        .empty-right {
            height: inherit;
            width: 8px;
            border-left: 1px solid black;
            float: right;
        }
    }

    &:last-of-type {
        border-bottom: none;
    }
}


@media only screen and (min-width: 1018px) {
    .overlayProps-container {
        height: 40px;
    
        .overlayProps {
            height: inherit;
    
            .overlay-name {
            float: left;
            display: block;
            width: 180px;
            line-height: 39px;
            padding-right: 8px;
            border-right: 1px solid #000000;
    
                .activeOverlayIndicator {
                    display: block;
                    float: left;
                    margin: 11px;
                    width: 16px;
                    height: 16px;
                    border-radius: 50%;
                    background-color: #8E8E93;
                }
            }
    
            .toggleOverlay,
            .overlaySettings {
                float: left;
                margin-top: 4px;
                margin-left: 16px;
                padding: 0 6px;
                border: 1px solid #8E8E93;
                border-radius: 6px;
                color:#8E8E93;
                cursor: pointer;
    
                svg {
                    height: 28px;
                    width: 16px;
                }
    
                &:hover {
                    color: #cccccc;
                    border-color: #cccccc;
                    }
            }
    
            &.active {
                .activeOverlayIndicator {
                    background-color: #47C3F2;
                }
            }
    
            .empty-right {
                height: inherit;
                width: 8px;
                border-left: 1px solid black;
                float: right;
            }
        }
    
        &:last-of-type {
            border-bottom: none;
        }
    }
}