.thumbnail-container{
    position: relative;
    display: none;
    width: 100%;
    top: -36px;
    z-index: 1000;
    
    &.visible{
      display: block;
    }
  }

  .thumbnail-video-container{
    position: absolute;
    top: -14px;
    border: 1px solid white;
    background-color: black;
  }

  #thumbnail-canvas{
    width: 100px;
    height: auto;
  } 

  .videoThumb {
    width: 100px;
    height: auto;
    display: block;
  }