.mtk1 {
    color: #8E8E93 !important;
}


.editor-legend {
    position: absolute;
    top: calc(100vh - 90px);
    left: 0;
    height: 40px;
    width: calc(100% - 14px);
    background-color: rgba(0,0,0, 0.6);
    color: #c3c3c3;
    padding: 8px 24px;
    font-size: 16px;
    line-height: 30px;
    text-align: right;

    .label {
        float: left;
        margin-left: 20px;
        font-size: 14px;
    }
}

@media only screen and (min-width: 1018px) {

    .editor-legend {
        position: absolute;
        top: calc(100vh - 90px);
        left: 0;
        height: 80px;
        width: calc(100% - 14px);
        background-color: rgba(0,0,0, 0.6);
        color: #c3c3c3;
        padding: 8px 24px;
        font-size: 16px;
        line-height: 30px;
        text-align: right;
    
        .label {
            float: left;
            margin-left: 20px;
            font-size: 14px;
        }
    }
    

}