

.timelineTopBar {
    display: block;
    width: 100%;
    height: 130px;
    overflow: auto;
    border-top: 2px solid #000000;
    border-bottom: 2px solid #000000;
    background-color: #212121;
}

@media only screen and (min-width: 1018px) {
    .timelineTopBar {
        display: block;
        width: 100%;
        height: 65px;
        overflow: auto;
        border-top: 2px solid #000000;
        border-bottom: 2px solid #000000;
        background-color: #212121;
    }
}