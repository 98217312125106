.overlay-row {
    clear: both;
    border-bottom: 1px solid #000000;
    overflow: auto;
    // background-color: #3a3a3a;

    .overlay-container,
    .overlayProps-container {
        display: block;
        float: left;
    }

    .overlayProps-container {
        width: 220px;
    }

    .overlay-container {
        width: calc(100% - 228px);
    }
}

[data-simplebar] {
    top: -4px;
}



@media only screen and (min-width: 1018px) {
    .overlay-row {
        clear: both;
        border-bottom: 1px solid #000000;
        overflow: auto;
        // background-color: #3a3a3a;
    
        .overlay-container,
        .overlayProps-container {
            display: block;
            float: left;
        }
    
        .overlayProps-container {
            width: 300px;
        }
    
        .overlay-container {
            width: calc(100% - 308px);
        }
    }
    
    [data-simplebar] {
        top: -4px;
    }
    
}