/* -------  VARIABLES  --------- */
$heightButtons: 40px;
$navHeight: 57px;
/* ----------------------------- */

nav {
    height: $navHeight;
    background-color: #2c2c2e;
    border-bottom: 1px solid #000000;

    &.templates {

        #left-arrow {
            cursor: not-allowed;
            color:#8e8e93 !important;

            &:hover {
                color:#8e8e93 !important;
            }
        }
    }
}

.leftSide {
    float: left;
    width: 242px;
    border-right: 1px solid #000000;
    padding: 8px 24px 0 24px;
    height: calc(#{$navHeight} - 1px);

    #hub-btn {
        padding: 0 16px;
        margin-right: 16px;
        border-radius: 20px;
        height: $heightButtons;
        vertical-align: middle;
        color: #ffffff;
    
        span {
            display: inline-block;
            margin-right: 8px;
            font-size: 14px;
            line-height: $heightButtons;
        }
    
        .hub-icon {
            margin-top: 0;
            line-height: 1;
            position: relative;
            top: -1px;
            width: 16px;
            height: 16px;
        }

        &:hover {
            color: #47C3F2;
        }
    }
    
    .navArrow {
        width: 40px;
        height: $heightButtons;
        vertical-align: middle;
        border-radius: 50%;
        text-align: center;
        color: #ffffff;
    
        &:last-of-type {
            margin-left: 8px;
        }

        &:hover {
            color: #47C3F2;
        }

        &.disabled {
            cursor: default;
            color: #8E8E93!important;
        }
    }
    
    #nav-back {
        width: 16px;
        height: $heightButtons;
    }
    
    #nav-forward {
        transform: rotate(180deg);
        width: 16px;
        height: $heightButtons;
    }
}

.rightSide {
    float: right;
    width: calc(100% - 242px);
    height: calc(#{$navHeight} - 1px);
    padding: 8px 48px 0 24px;

    .templateInfo {
        display: inline-block;

        &.empty {
            display: none;
        }

        .templateNameContainer,
        .templateVersionContainer {
            float: left;
            // opacity: 0;

            span {
                display: inline-block;
                line-height: $heightButtons;
                font-size: 12px;
                color: #8e8e93;
            }

            .label {
                margin-right: 12px;
            }

            .details {
                padding: 0 18px;
                background-color: #1c1c1c;
                border-radius: 20px;
                font-size: 14px;
                color: #ffffff;
                margin-right: 16px;
            }

            &.visible {
                opacity: 1;
            }
        }
    }

    .textOnRightSide {
        display: block;
        float: right;
        font-size: 14px;
        line-height: $heightButtons;

        sup {
            font-size: 8px;
        }
    }
}


@media (max-width:919.9px) {
    .leftSide,
    .rightSide {
        float: none;
    }

    .rightSide {
        width: 100%;
        position: relative;

        .templateInfo {
            display: block;
        }

        .textOnRightSide {
            float: none;
            position: absolute;
            top: -48px;
            right: 36px;
        }
    }
}

@media (max-width:767.9px) {
}

@media (max-width:449.9px) {
    nav {
        height: auto;
    }
    .rightSide {
        height: auto;
        padding: 8px 24px;

        .templateInfo {
            overflow: auto;
        }

        .textOnRightSide {
            position: static;
            width: 100%;
            text-align: right;
        }
    }
}