/* -------  VARIABLES  --------- */
$heightButtons: 40px;
$maxWidthPage: 1746px;
/* ----------------------------- */

.error-container span {
    display: block;
    //width: 100%;
   //text-align: center;
    padding: 0 0 10px 0;
    color: #ff5d5d;
    font-size: 14px;
}
.closeBtn {
    float: right;
    cursor: pointer;
}
.actions-container {
    padding: 0 48px;
    max-width: $maxWidthPage;
    margin: 0 auto;

    button {
        line-height: $heightButtons;
        color: #ffffff;
        border-radius: 20px;
        padding: 0 20px;
        font-size: 14px;

        svg {
            position: relative;
            top: -1px;
            width: 16px;
        }

        &:hover {
            color: #47C3F2;
        }
    }

    .sortAndPagination {
        //display: inline-block;
        float: right;
        color: #8E8E93;
        margin-top: -40px;

        .pagination {
            margin-left: 56px;

            span {                
                font-size: 16px;
                line-height: 42px;
                display: block;
                float: left;
            }

            button {
                &.disabled {
                    cursor: not-allowed;
                    color: #111111;

                    &:hover {
                        color:#111111;
                    }
                }
            }
        }

        .sort,
        .pagination {
            display: inline-block;
            font-size: 14px;
            line-height: 32px;

            .sortBy,
            .prev,
            .next {
                padding: 0;
                width: 42px;
                line-height: 40px;
                margin-left: 8px;
                border-radius: 50%;
                text-align: center;
                vertical-align: middle;
                color: #8E8E93;

                svg {
                    width: 16px;
                }

                &:hover {
                    color: #47C3F2;
                }
            }

            .next {
                margin-left: 4px;
                transform: rotate(180deg);
            }
        }
    }
    

}

.templatesContainer {
    padding: 24px 40px;
    max-width: $maxWidthPage;
    margin: 0 auto;

    .templatesInnerContainer {
        margin: 0 auto;

        .infoPanel {
            display: inline-block;
            width: 192px;
            margin-bottom: 24px;
            margin-left: 8px;
            margin-right: 8px;
            background-color: #3a3a3a;
            border-radius: 5px;
            overflow: hidden;
            padding-bottom: 16px;

            button {
                display: block!important;
                width: 136px;
                margin: 16px auto 0 auto;
                padding: 0 16px;
                text-align: left;
                line-height: $heightButtons;
                border-radius: 20px;
                color: #ffffff;

                svg {
                    float: right;
                    width: 16px;
                    height: $heightButtons;
                }

                .saveBtn {
                    display: none;
                }

                &:hover {
                    color: #47C3F2;
                }
            }

            .prevImage {
                width: 192px;
                height: 100px;
                overflow: hidden;
                position: relative;
                cursor: pointer;
                background-image: url('https://picsum.photos/450/300');
                background-size: cover;

                // img {
                //     width: 100%;
                // }
                .background-div {
                    width: inherit;
                    height: inherit;
                    background-color: rgba(0,0,0,0.6);
                    opacity: 0;
                    transition: opacity ease 0.5s;
                }

                .uploadImgBtn {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    margin-left: -20px;
                    margin-top: -20px;
                    opacity: 0;
                    width: 40px;
                    height: 40px;
                    transition: opacity ease 0.5s;
                }

                &:hover {
                    .background-div {
                        opacity: 1;
                    }
                    .uploadImgBtn {
                        opacity: 1;
                    }
                }
                
            }

            .rowInfo {
                padding: 4px 0;
                border-bottom: 1px solid #4a4a4d;
                width: calc(100% - 16px);
                margin: 0 auto;

                label {
                    font-size: 10px;
                    width: 50px;
                    display: inline-block;
                    text-align: right;
                }

                textarea {
                    display: inline-block;
                    width: 115px;
                    height: 35px;
                    vertical-align: top;
                    margin-left: 8px;
                    padding-top: 4px;
                    resize: none;
                    font-size: 10px;
                    background-color: transparent;
                    color: #ffffff;
                    border: none;
                }
            }

            &.editing {
                button {
                    .editBtn {
                        display: none;
                    }

                    .saveBtn {
                        display: block;
                    }
                }
            }
        }
            
    }
}

.add-template-modal {
    // background-color: #3a3a3a;
    // position: absolute;
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%, -50%);
    // padding: 16px;

    input {
        background-color: transparent;
        color: #ffffff;
        border: none;
        width: 160px;
        border-bottom: 1px dashed;
    }

    label {
        display: inline-block;
        width: 100px;
        padding-bottom:24px;
    }
}
.actionArea{
    display: flex;
    padding: 16px;
    margin-top: 32px;
    float: right;
}

@media (max-width:1796.9px) {
    $maxWidthPage: 1538px;

    .templatesContainer,
    .actions-container {
        max-width: $maxWidthPage;
    }
}

@media (max-width:1588.9px) {
    $maxWidthPage: 1330px;

    .templatesContainer,
    .actions-container {
        max-width: $maxWidthPage;
    }
}

@media (max-width:1382.9px) {
    $maxWidthPage: 1122px;

    .templatesContainer,
    .actions-container {
        max-width: $maxWidthPage;
    }
}

@media (max-width:1174.9px) {
    $maxWidthPage: 914px;

    .templatesContainer,
    .actions-container {
        max-width: $maxWidthPage;
    }
}

@media (max-width:966.9px) {
    $maxWidthPage: 706px;

    .templatesContainer,
    .actions-container {
        max-width: $maxWidthPage;
    }
}

@media (max-width:759.9px) {
    $maxWidthPage: 498px;

    .templatesContainer,
    .actions-container {
        max-width: $maxWidthPage;
        padding: 24px 16px;
    }

    .templatesContainer {
        text-align: center;
    }

    .actions-container .sortAndPagination {
        display: block;
        float: none;
        margin-top: 8px;
        text-align: right;
    }
}

@media (max-width:499.9px) {
    $maxWidthPage: 320px;

    .templatesContainer,
    .actions-container {
        max-width: $maxWidthPage;
    }

    .actions-container {
        padding: 24px 8px;
        
        .sortAndPagination {
            float: none;
            margin-top: 16px;
        }
    }

    .templatesContainer {
        
        .templatesInnerContainer {
            
            .infoPanel {
                display: block;
                margin-left: auto;
                margin-right: auto;
            }
        }
    }
}

@media (max-width:339.9px) {
    .actions-container {
        padding: 24px 0;
    }
}