.overlay-row {
  clear: both;
  border-bottom: 1px solid #000000;
  overflow: auto; }
  .overlay-row .overlay-container,
  .overlay-row .overlayProps-container {
    display: block;
    float: left; }
  .overlay-row .overlayProps-container {
    width: 220px; }
  .overlay-row .overlay-container {
    width: calc(100% - 228px); }

[data-simplebar] {
  top: -4px; }

@media only screen and (min-width: 1018px) {
  .overlay-row {
    clear: both;
    border-bottom: 1px solid #000000;
    overflow: auto; }
    .overlay-row .overlay-container,
    .overlay-row .overlayProps-container {
      display: block;
      float: left; }
    .overlay-row .overlayProps-container {
      width: 300px; }
    .overlay-row .overlay-container {
      width: calc(100% - 308px); }
  [data-simplebar] {
    top: -4px; } }
