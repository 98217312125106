
.timeline-component-container {
  height: inherit;
  background-color: #313131;
}

.timelineBar {
  display:inline-block;
  position:relative;
  width: 100%;
  border-top: 1px solid #000000;
}


.timeline-left {
  position: relative;
  display: block;
  width: 220px;
  float: left;
  min-height: 58px;
  border-bottom: 2px solid #000;

  /* ---|  Add Layer +  |--- */
  #addOverlay,
  #addSubtitle {
    width: 136px;
    font-size: 14px;
    height: 40px;
    color: #ffffff;
    line-height: 38px;
    padding: 0 16px;
    text-align: left;
    border-radius: 20px;
    margin: 8px 0 0 16px;

    svg {
      float: right;
      height: 40px;
      width: 16px;
    }
    
    &:hover{
      background-color: #37373a;
      color: #47C3F2;
    }
  }
  /* ------------------------- */
  .empty-right {
    height: 56px;
    width: 8px;
    border-left: 1px solid black;
    float: right;
  }
}

@media only screen and (min-width: 1018px) {
  .timeline-left {
    position: relative;
    display: block;
    width: 300px;
    float: left;
    min-height: 58px;
    border-bottom: 2px solid #000;
  
    /* ---|  Add Layer +  |--- */
    #addOverlay,
    #addSubtitle {
      width: 136px;
      font-size: 14px;
      height: 40px;
      color: #ffffff;
      line-height: 38px;
      padding: 0 16px;
      text-align: left;
      border-radius: 20px;
      margin: 8px 0 0 16px;
  
      svg {
        float: right;
        height: 40px;
        width: 16px;
      }
      
      &:hover{
        background-color: #37373a;
        color: #47C3F2;
      }
    }
    /* ------------------------- */
    .empty-right {
      height: 56px;
      width: 8px;
      border-left: 1px solid black;
      float: right;
    }
  }
}

/* ---------------------------------------- */
/* ---------------------------------------- */

.timeline-right {
  display: block;
  width: calc(100% - 220px);
  float: left;
  border-bottom: 2px solid #000000;

  .timeline-container {    
    position: relative;
    padding: 8px 0;
    width: calc(100% - 8px);

    .timeline {
      display: block;
      width: 100%;
      height: 40px;
      background-color: rgba(72, 108, 124, 0.8);
      cursor: pointer;
      position: relative;
      border-radius: 4px;
      //z-index: 1;

      .timeline__drag {
        width: 1px;
        top: -14px;
        left: 25%;
        background-color: #47C3F2;
        position: absolute;
        z-index: 2;
        transform-origin: 0 0;
      }

      .timeline__progress {
        display: block;
        height: 100%;
        background-color: rgba(0,0,0,0.8);
        position: relative;
        z-index: 1;
        border-radius: 4px;
      }
    }
  }

}

@media only screen and (min-width: 1018px) {
  .timeline-right {
    display: block;
    width: calc(100% - 300px);
    float: left;
    border-bottom: 2px solid #000000;
  
    .timeline-container {      
      position: relative;
      padding: 8px 0;
      width: calc(100% - 8px);
  
      .timeline {
        display: block;
        width: 100%;
        height: 40px;
        background-color: rgba(72, 108, 124, 0.8);
        cursor: pointer;
        position: relative;
        border-radius: 4px;
  
        .timeline__drag {
          width: 1px;
          top: -14px;
          left: 25%;
          background-color: #47C3F2;
          position: absolute;
          z-index: 2;
          transform-origin: 0 0;
        }
  
        .timeline__progress {
          display: block;
          height: 100%;
          background-color: rgba(0,0,0,0.8);
          position: relative;
          z-index: 1;
          border-radius: 4px;
        }
      }
    }
  
  }
}