body.col-resize {
    cursor: col-resize; }
  
  body.row-resize {
    cursor: row-resize; }
  
  .reflex-container {
    justify-content: flex-start;
    /* align items in Main Axis */
    align-items: stretch;
    /* align items in Cross Axis */
    align-content: stretch;
    /* OLD - iOS 6-, Safari 3.1-6 */
    /* OLD - Firefox 19- (buggy but mostly works) */
    /* TWEENER - IE 10 */
    /* NEW - Chrome */
    display: flex;
    position: relative;
    height: 100%;
    width: 100%; }
  
  .reflex-container.horizontal {
    flex-direction: column;
    min-height: 1px; }
  
  .reflex-container.vertical {
    flex-direction: row;
    min-width: 1px; }
  
  .reflex-container > .reflex-element {
    position: relative;
    overflow: hidden;
    height: 100%;
    width: 100%; }
  
  .reflex-container.reflex-resizing > .reflex-element {
    pointer-events: none;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none; }
  
  .reflex-container > .reflex-element > .reflex-size-aware {
    height: 100%;
    width: 100%; }
  
  .reflex-container > .reflex-splitter {
    background-color: rgba(255, 255, 255, 0);
    transition: background-color 0.2s ease-out;
    z-index: 100; }
  
  .reflex-container > .reflex-splitter.active,
  .reflex-container > .reflex-splitter:hover {
    background-color: rgba(255, 255, 255, 0.2);
    transition: none; }
  
  .reflex-container.horizontal > .reflex-splitter {
    /* border-bottom: 1px solid #131313;
    border-top: 1px solid #131313; */
    cursor: row-resize;
    width: 100%;
    margin: -2px 0;
    height: 4px; }
  
  .reflex-element.horizontal .reflex-handle {
    cursor: row-resize;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none; }
  
  /* .reflex-container.horizontal > .reflex-splitter:hover,
  .reflex-container.horizontal > .reflex-splitter.active {
    border-bottom: 1px solid #181818;
    border-top: 1px solid #181818; } */
  
  .reflex-container.vertical > .reflex-splitter {
    /* border-right: 1px solid #131313;
    border-left: 1px solid #131313; */
    cursor: col-resize;
    height: 100%;
    margin: 0 -2px;
    width: 4px; }
  
  .reflex-element.vertical .reflex-handle {
    cursor: col-resize;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none; }
  
  /* .reflex-container.vertical > .reflex-splitter:hover,
  .reflex-container.vertical > .reflex-splitter.active {
    border-right: 1px solid #181818;
    border-left: 1px solid #181818; } */
  
  .reflex-container > .reflex-splitter.reflex-thin {
    box-sizing: border-box;
    -moz-background-clip: padding;
    -webkit-background-clip: padding;
    background-clip: padding-box;
    opacity: 0.2;
    z-index: 100; }
  
  .reflex-container > .reflex-splitter.reflex-thin.active
  .reflex-container > .reflex-splitter.reflex-thin:hover {
    transition: all 1.5s ease;
    opacity: 0.5; }
  
  .reflex-container.horizontal > .reflex-splitter.reflex-thin {
    border-bottom: 8px solid rgba(255, 255, 255, 0);
    border-top: 8px solid rgba(255, 255, 255, 0);
    height: 17px !important;
    cursor: row-resize;
    margin: -8px 0;
    width: 100%; }
  
  .reflex-container.horizontal > .reflex-splitter.reflex-thin.active,
  .reflex-container.horizontal > .reflex-splitter.reflex-thin:hover {
    border-bottom: 8px solid #131313;
    border-top: 8px solid #131313; }
  
  .reflex-container.vertical > .reflex-splitter.reflex-thin {
    border-right: 8px solid rgba(255, 255, 255, 0);
    border-left: 8px solid rgba(255, 255, 255, 0);
    width: 17px !important;
    cursor: col-resize;
    margin: 0 -8px;
    height: 100%; }
  
  .reflex-container.vertical > .reflex-splitter.reflex-thin.active,
  .reflex-container.vertical > .reflex-splitter.reflex-thin:hover {
    border-right: 8px solid #131313;
    border-left: 8px solid #131313; }




/* -----------------------------------------------------------------  */
/* -----------------------------------------------------------------  */

.loading-container {
  width: inherit;
  height: 100%;

//   element.style {
//     width: 40vw;
//     height: 40vh;
//     top: 20vh;
//     left: 30vw;
//     z-index: 1000;
//     position: absolute;
//   }
 }

.staticInterface {
  position: relative;
  width: 100vw;
  height: 100%;
}

.dynamicElements {
  position: absolute;
  top: 0;
  right: -540px;
  width: 500px;
  height: 100vh;
  z-index: 1000;
  transition: right ease 0.5s;

  .adminComponent {
    height: inherit;
    opacity: 0;
  }

  &.visible {
    right: 0;

    .adminComponent {
      opacity: 1;
    }
  }
}
@media (max-width: 1018px) {
  .dynamicElements {
    position: absolute;
    top: 0;
    right: -540px;
    width: 400px;
    height: 100vh;
    z-index: 1000;
    transition: right ease 0.5s;
  
    .adminComponent {
      height: inherit;
      opacity: 0;
    }
  
    &.visible {
      right: 0;
  
      .adminComponent {
        opacity: 1;
      }
    }
  }
}


/* -----------------------------------------------------------------  */
/* -----------------------------------------------------------------  */