.scene-modal {
    // background-color: #3a3a3a;
    // position: absolute;
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%, -50%);
    // padding: 16px;
    // border-radius:4px;

    .MuiPaper-root {
        width: 400px;
        max-width: 100%;
        margin: 8px;
    }

    .scene-modal-title {
        text-align: center;
    }

    .scene-modal-content {

        .add-scene-message,
        #new-scene-video-id-label {
            display: inline-block;
            font-size: 12px;
            color: #eaeaea;
        }

        .add-scene-message {
            min-width: 180px;
            text-align: right;
        }

        #new-scene-video-id-label {
            width: 180px;
            text-align: right;
            line-height: 26px;
        }

        .selectInput {
            margin-left: 16px;
        }

        .input-wrapper {
            clear: both;
            margin-bottom: 8px;
            
            label {
                display: block;
                float: left;
                text-align: right;
                width: 180px;
                margin-right: 8px;
                line-height: 30px;
                font-size: 12px;
                color: #eaeaea;
            }

            input {
                padding: 2px 4px;
                background-color: transparent;
                color: #ffffff;
                border: none;
                width: 160px;

                &:focus,
                &:active {                    
                    outline: none;
                    border-bottom: 1px solid #eeeeee;
                }

                &.scene-name-input{
                    margin-left: 8px;
                }
            }
            .MuiInputBase-root {
                font-size: inherit;
            }
        }

        span.error-msg {
            color: #ff5b5b;
            font-size: 12px;
        }
        p.error-msg {
            color: #ff5b5b;
            font-size: 12px;
            text-align: right;
        }

       
    }
    //  .actionArea{
    //     display: flex;
    //     padding: 16px;
    //     margin-top: 32px;
    //     float: right;
    // }
}

@media all and (max-width:415px) {
    .scene-modal {
        .scene-modal-content {
            #new-scene-video-id-label {
                display: block;
                text-align: left;
            }
            .input-wrapper {
                label {
                    display: block;
                    text-align: left;
                }
                input{
                    &.scene-name-input {
                        margin-left: 0;
                    }
                }
            }
            .MuiInputBase-root {
                font-size: inherit;
            }
        }
    }
}