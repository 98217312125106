.scenes-bar {
    overflow: auto;
    background-color: #212121;

    .tab,
    .add-scene-btn {
        display: block;
        float: left;
        margin-left: 8px;
        text-align: center;
        cursor: pointer;
    }
    
    .tab {
        // width: 80px;
        padding: 0 10px;
        line-height: 40px;
        color: #6e6e6e;
        background-color: #212121;
        border: none;
        outline: none;
        border-left: 1px solid #111;
        border-right: 1px solid #111;

        &.active {
            color: #888888;
            background-color: #3a3a3a;
        }
    }

    .add-scene-btn {
        height: 40px;
        width: 50px;
        padding: 8px 0;

        &:hover {
            background-color: #2f2f2f;
        }
    }
}

.videoSrc-tooltip {
    width:200px;
    font-size: 14px!important;
}